import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState, useCallback } from 'react';

import { useCurrentUser } from '@/auth/useCurrentUser';
import LoadingSpinner from '@/components/feedback/LoadingSpinner';
import { useLogUserSessionMutation } from '@/generated/graphql';
import { handleError } from '@/utils/errors';

interface UserSessionData {
  id: string | null | undefined;
  isReady: boolean;
}

const LOCAL_STORAGE_KEY = 'userSessionId';

export const WithUserSession = ({ children }: { children: ReactNode }) => {
  const [userSessionData, setUserSessionData] = useState<UserSessionData>({
    id: null,
    isReady: false,
  });

  const currentUser = useCurrentUser();
  const router = useRouter();

  const [logUserSessionMutation] = useLogUserSessionMutation();

  const logSession = useCallback(
    async (userSessionId: string | undefined | null): Promise<void> => {
      if (!currentUser?.id) return;

      try {
        const response = await logUserSessionMutation({
          variables: {
            lastPath: router.asPath,
            userSessionId,
          },
        });

        const sessionId = response.data?.logUserSession?.id;
        if (!sessionId) {
          handleError(
            new Error(
              `Failed to get session ID for email: ${currentUser.email}`
            ),
            true
          );
          return;
        }

        const normalizedUserSessionId =
          sessionId === userSessionId ? userSessionId : sessionId;
        localStorage.setItem(LOCAL_STORAGE_KEY, normalizedUserSessionId);

        setUserSessionData({
          id: normalizedUserSessionId,
          isReady: true,
        });
      } catch (error) {
        handleError(error, true);
      }
    },
    [logUserSessionMutation, router.asPath, currentUser?.id, currentUser?.email]
  );

  // log initial session
  useEffect(() => {
    const storedSessionId = localStorage.getItem(LOCAL_STORAGE_KEY);

    logSession(storedSessionId);

    setUserSessionData({
      id: storedSessionId,
      isReady: true,
    });
  }, []);

  // log session for valid userSessionId
  useEffect(() => {
    if (!userSessionData.id) return;

    const handleRouteChange = () => {
      void logSession(userSessionData.id);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [logSession, router.events, userSessionData.id]);

  // Session polling w valid userSessionId
  useEffect(() => {
    if (!userSessionData.id) return;

    let interval: NodeJS.Timeout | null = null;

    const startPolling = () => {
      if (!interval) {
        interval = setInterval(
          () => {
            void logSession(userSessionData.id);
          },
          5 * 60 * 1000
        );
      }
    };

    const stopPolling = () => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };

    const handleVisibilityChange = () => {
      document.hidden ? stopPolling() : startPolling();
    };

    startPolling();
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      stopPolling();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [userSessionData.id, logSession]);

  if (!userSessionData.isReady) return <LoadingSpinner />;

  return <>{children}</>;
};
