import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import merge from 'deepmerge';
import { createClient } from 'graphql-ws';
import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';

import { GRAPHQL_URL, GRAPHQL_URL_WS } from '@/config';

import { isUnavailableModalOpenVar } from '@/shared-state/common/feedback';

import { handleError } from '@/utils/errors';

import { skipTakePagination } from './apolloUtilities';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const errorLink = onError(errorResponse => {
  const { graphQLErrors, networkError } = errorResponse;

  // Get all errors
  const allErrors = [...(graphQLErrors || []), networkError];

  for (const error of allErrors) {
    if (!error) {
      continue;
    }

    // Get the error code and report error to Sentry
    const { code } = handleError(error, true);

    // If the server is unavailable, open the modal
    if (code === 'network.server.unavailable') {
      // isUnavailableModalOpenVar(true);
      console.error(
        '🟥🟥🟥 Server is unavailable',
        JSON.stringify(error, null, 2)
      );
    }
  }
});

// On the client we store the apollo client in the following variable
// this prevents the client from re-initializing between page transitions
let apolloClient;

const httpLink = createUploadLink({
  credentials: 'include', // Additional fetch() options like `credentials` or `headers`
  fetch,
  // TODO: [CRITICAL:SECURITY] - This is a temporary fix to fix CORS CSRF issue when uploading documents.
  // Review this to make sure it's not a security issue. @scott-pmd @VladKrasnozhon @ScottAgirs
  headers: {
    'Apollo-Require-Preflight': 'true',
  },
  uri: ({ operationName }) => {
    return `${GRAPHQL_URL}?${operationName}`;
  }, // Server URL (must be absolute)
});

const wsLink =
  typeof window !== 'undefined'
    ? new GraphQLWsLink(
        createClient({
          url: GRAPHQL_URL_WS,
        })
      )
    : httpLink;

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

// TODO: Needs to review `errorLink`
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const link = ApolloLink.from([errorLink, splitLink]);

function createApolloClient() {
  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Doctor: {
          fields: {
            appointmentRequests: skipTakePagination(['where']),
            appointments: skipTakePagination(['where']),
            bookings: skipTakePagination(['where']),
            caringForPatients: skipTakePagination(),
            faxes: skipTakePagination(['where']),
            testOrders: skipTakePagination(),
          },
        },
        Patient: {
          fields: {
            allergies: skipTakePagination(['where']),
            appointmentRequests: skipTakePagination(['where']),
            appointments: skipTakePagination(['where']),
            bookings: skipTakePagination(['where']),
            documents: skipTakePagination(['where']),
            healthConditions: skipTakePagination(['where']),
            lifestyleItems: skipTakePagination(['where']),
            medicationItems: skipTakePagination(['where']),
            screeningTests: skipTakePagination(['where']),
            vaccinations: skipTakePagination(['where']),
          },
        },
        Query: {
          fields: {
            appointmentBillings: skipTakePagination(['where']),
            appointmentRequests: skipTakePagination(['where', 'orderBy']),
            appointments: skipTakePagination(['where', 'orderBy']),
            bookings: skipTakePagination(),
            chatMessages: skipTakePagination(['where', 'orderBy']),
            doctors: skipTakePagination(['where']),
            faxRecipients: skipTakePagination(['where', 'orderBy']),
            faxes: skipTakePagination(['where', 'orderBy']),
            getAppointmentBillings: skipTakePagination([
              'companyId',
              'filters',
              'orderBy',
              'searchTerm',
              'status',
            ]),
            healthConditions: skipTakePagination(['where']),
            inboundFaxes: skipTakePagination(['where', 'orderBy']),
            lifestyleItems: skipTakePagination(['where']),
            medicationItems: skipTakePagination(['where']),
            patients: skipTakePagination(['where']),
            tasks: skipTakePagination(['where', 'orderBy']),
            testOrders: skipTakePagination(['where', 'orderBy']),
            userInvites: skipTakePagination(['where']),
            users: skipTakePagination(['where', 'orderBy']),
          },
        },
      },
    }),
    link,
    ssrMode: typeof window === 'undefined',
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);

  return store;
}

export const globalApolloClient = initializeApollo();
